<template>
    <div class="index">
        <Header backType="white" backEvent="true" @backEvent="backPage" :back="true"></Header>
        <div class="publicHead mt-2 mb1-5">
            <p>
                <span>推荐餐厅首页</span>
            </p>
        </div>
        <p class="shopType">请选择用餐形式</p>
        <div class="mode" @click="next" >
            <Svgs color="#fff" classes="shopSvg" name="iconshangdianfeicanyin"></Svgs>
            <div class="mode-name">外出用餐</div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        backPage() {
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler(
                    "SmGeneral.closeWebSite",
                );
            }else{
                this.iJsBridge.close();
            }
        },
        next(type){
            this.$router.push({path:'/recommendShop'})
        }
    }
}
</script>

<style lang="scss">
.shopType{
    margin: 0 .25rem;
    margin-bottom: .25rem;
    color: #90939A;
    font-size: .14rem;
    text-align: left;
}
.mode{
    width: 1.42rem;
    height: 1.42rem;
    border-radius: 12px;
    background:linear-gradient(-55deg,rgba(156,84,254,1),rgba(215,138,254,1));
    position: relative;
    margin: 0 .25rem;
}
.mode-name{
    font-size: .17rem;
    color: #fff;
    position: absolute;
    bottom: .3rem;
    left: .15rem;
}
.shopSvg{
    position: absolute;
    left: .15rem;
    top: .26rem;
    width: .35rem !important;
    height: .35rem !important;
}
</style>